
.christmas-content{
    position:relative;
    background-color:black;
    height:100vh;
    overflow: hidden; /* Hide scrollbars */
    font-size: xx-large;
    background-image: url(christmas_tree.jpeg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}
.red{
    color:red;
}
.green{
    color:green;
}