.unfinished-text{
    margin:auto;
    padding-top: 20px;
    padding-bottom:20px;
    font-size:50px;
    font-family:sans-serif;
    margin-top: 20px;
    margin-bottom: 20px;
    color:rgb(165, 108, 42);
    font-weight: bolder;
    text-shadow: 0 0 20px rgb(165, 108, 42), 0 0 8px orange;
}

.unfinished-img{
    margin:auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

a{
    padding: 10px;
    text-decoration: none;
}

.sun{
    border-radius: 50%;
    background-color: orange;
    width:200vw;
    height:200vw;
    top:-195vw;
    left:-50vw;
    position:absolute;
    box-shadow: orange 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.mercury{
    border-radius: 50%;
    background-color: gray;
    width:25vh;
    height:25vh;
    top: 20vh;
    left: 20vw;
    position:absolute;
}
.venus{
    border-radius: 50%;
    background-color:rgb(212, 172, 40);
    color:rgb(165, 108, 42);
    width:65vh;
    height:65vh;
    top: 30vh;
    right: 15vw;
    position:absolute;
    }
.earth{
    border-radius: 50%;
    background-color: blue;
    color:rgb(47, 196, 2);
    width:65vh;
    height:65vh;
    top: 55vh;
    left: 10vw;
    position:absolute;
    box-shadow: blue 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.earth div{
    margin:auto;
    max-width:20vw;
    background-color: aliceblue;
    margin-top:10vw;
    color:blue;
    border-radius:20px;
    display: none;
}
.moon{
    border-radius: 50%;
    background-color: gray;
    color:darkgray;
    width:25vh;
    height:25vh;
    top: 100vh;
    left: 5vw;
    position:absolute;
}
.mars{
    color:rgb(253, 1, 1);
    background-color: rgb(148, 26, 26);
    border-radius: 50%;
    width:40vh;
    height:40vh;
    top: 105vh;
    left: 40vw;
    position:absolute;
}
.home-content{
    position:relative;
    height:150vh;

    overflow: hidden; /* Hide scrollbars */
}

.planet{
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}