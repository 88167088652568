.harthos-text{
    margin:auto;
    padding-top: 50px;
    padding-bottom:50px;
    font-size:50px;
    font-family:sans-serif;
    color:rgb(20, 199, 20);
    font-weight: bolder;
    text-decoration: none;
    /* border: 3px solid green; */
    text-shadow: 0 0 8px green, 0 0 8px greenyellow;
    margin-top: 20px;
    margin-bottom: 20px;
}
.not-harthos-text{
    margin:auto;
    padding-top: 50px;
    padding-bottom:50px;
    font-size:50px;
    font-family:sans-serif;
    color:whitesmoke;
    font-weight: bold;
    text-decoration: none;
    /* border: 3px solid green; */
    text-shadow: 0 0 8px rgb(0, 98, 128), 0 0 8px rgb(47, 120, 255);
    margin-top: 20px;
    margin-bottom: 20px;
}

.nat-twenty {
    color:rgb(218, 230, 50);
    animation: nat-twenty 2s linear infinite;
  }
  
  @keyframes nat-twenty {
    0%{
        color:red;
    }
    14%{
        color:orange;
        
    }
    28%{
        color:yellow;
    }
    42%{
        color:green;
    }
    56% {
        color:blue;
    }
    70%{
        color:rgb(0, 156, 156);
    }
    84%{
        color:pink;
    }
    93%{
        color:purple;
    }
    100%{
        color:red;
    }
  }